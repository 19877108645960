// import { Form, Text } from 'informed'; //https://joepuzzo.github.io/informed/
import React from 'react';

import MUIRichTextEditor from 'mui-rte';
import ToastServive from 'react-material-toast';

import {
    Button,
    FormControl,
    FormHelperText,
    TextareaAutosize,
    TextField,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
    CircularProgress,
    NativeSelect,
} from '@material-ui/core';
import Card, {
    CardPrimaryContent,
    CardActions,
    CardActionButtons,
} from "@material/react-card";
import '@material/react-card/dist/card.css';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


const axios = require('axios');
const FormData = require('form-data');

//const API_BASE = 'http://localhost:3000/';
const API_BASE = 'https://potrika.ue.r.appspot.com/';
const GDRIVE_API_PATH='gdrive/submissions/add';
const GSHEET_API_PATH='gsheet/submissions/add';
const GDRIVE_BASE='https://drive.google.com/file/d/';

const toast = ToastServive.new({
    place:'topRight',
    duration:2,
    maxCount:8
});
const initialState = {
    // Initially, no file is selected
    selectedFile: null,
    Category: null,
    AuthorEmail: null,
    AuthorNameInBengali: null,
    AuthorAddressInEnglish: null,
    ArticleNameInBengali: null,
    AuthorPhone: null,
    ArticleDescription: null,
    submissionType: 'file',
    ArticleText: null,
    error: null,
    success: null,
    processing: null,
    selectedFileName: '',
    MarketingSource: '',
    MarketingSourceDetails: null,
};

export const isBengali = (val) => {
    // if ((val as string).length > 1) {
    for (let i = 0; i < (val as string).length; i++) {
        const charCode = (val as string).charCodeAt(i);
        if (!/\s/.test(val[i]) && !(charCode >= 0x980 && charCode <= 0x9FF) && !(charCode >= 0x20 && charCode <= 0x40)) {
            return false;
        }
    }
    //}
    return true;
}
export default class SubmitForm extends React.Component {
    state = initialState;

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this); //to tie the form's callback to this class
    }

    componentDidMount(){ //called automatically by React
        ValidatorForm.addValidationRule('hasContent', () => {
            return this.state.submissionType === 'text' && this.state.ArticleText;
        });
    }
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('hasContent');
    }
    reset = () => {
        this.setState(initialState);
        //console.log('reset called', this.state)
    }
    handleClientLoad = async ()=> { //initialize the Google API
    }

    initClient =()=> { //provide the authentication credentials you set up in the Google developer console
    }
    handleAuthorNameInBengali = async (e) => {
        this.setState({AuthorNameInBengali:
                (e.target.value && e.target.value.trim().length
                    && isBengali(e.target.value) && e.target.value)
                || this.state.AuthorNameInBengali})
    }
    handleAuthorEmail = async (e) => {
        this.setState({AuthorEmail: e.target.value && e.target.value.trim().length && e.target.value})
    }
    handleAuthorPhone = async (val) => {
        this.setState({AuthorPhone: val})
    }
    handleAuthorAddressInEnglish = async (e) => {
        this.setState({AuthorAddressInEnglish: e.target.value})
    }
    handleArticleNameInBengali = async (e) => {
        this.setState({ArticleNameInBengali:
            (e.target.value && isBengali(e.target.value) && e.target.value.trim().length && e.target.value)
                || this.state.ArticleNameInBengali});
    }
    handleArticleDescription = async (e) => {
        this.setState({ArticleDescription: e.target.value});
    }
    handleSubmissionOption = async (e) => {
        this.setState({submissionType: e.target.value});
    }
    handleRicheText = async (data) => {
        //console.log((data && JSON.parse(data)?.blocks?.[0]?.text))
        const json = JSON.parse(data);
        const text = json.blocks.reduce((accum, current) => `${accum}\n${current.text}`, '');
       // console.log(text)
        this.setState({ArticleText: (text && text.trim()) || null});
    }
    handleText = async (event) => {
        if (event.target.value) {
            this.setState({ArticleText: (event.target.value && event.target.value.trim()) || null});
        }
    }
    onFileChange = event => {
        //console.log('in file change')
        // Update the state
        const file = event.target.files[0];
        if (file?.size > 0) {
            this.setState({ selectedFile: event.target.files[0] });
        } else {
            this.setState({error: 'Please select a valid file. The chosen file is empty.'});
        }
    };
    onRemoveFile = event => {
        this.setState({ selectedFile: null });
    }
    onCategoryChange = event => {
        this.setState({ Category: event.target.value});
    }
    handleFileRead = (e) => {
    }
    handleMarketingSource = (e) => {
        this.setState({ MarketingSource: e.target.value});
    }
    handleMarketingSourceDetails = (e) => {
        this.setState({ MarketingSourceDetails: e.target.value});
    }
    createID = () => {
        let ID = "";
        let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        for ( var i = 0; i < 12; i++ ) {
            ID += characters.charAt(Math.floor(Math.random() * 36));
        }
        return ID;
    }
    // File content to be displayed after
    // file upload is complete
    fileData = () => {
        if (this.state.selectedFile) {
            return (
                <Card>
                <CardPrimaryContent>
                    <span>Name: {(this.state.selectedFile as any).name}</span>
                    <span>File Size: {(this.state.selectedFile as any).size} bytes</span>
                    <span>Last Modified: {(this.state.selectedFile as any).lastModifiedDate?.toDateString()}</span>
                </CardPrimaryContent>
                <CardActions>
                    <CardActionButtons>
                        <button onClick={this.onRemoveFile}>X</button>
                    </CardActionButtons>
                </CardActions>
            </Card>
            );
        } else {
            return null;
        }
    };
    onFormSubmit = async (values) => {
        const self = this;
        this.setState({processing: true});
        this.setState({success: true});
        this.setState({error: true});

        if (this.state.Category === null || this.state.Category === ''){
            this.setState({error: 'Please select a category'});
            this.setState({processing: false});
            return;
        }

        if (this.state.submissionType === 'file' && !this.state.selectedFile) {
            this.setState({error: 'ফাইল আপলোড করে জমা করুন'});
            this.setState({processing: false});
            return;
        }
        if (this.state.submissionType === 'text' && (!this.state.ArticleText || this.state.ArticleText === '')) {
            this.setState({error: 'লেখাটি বাংলায় লিখে জমা করুন'});
            this.setState({processing: false});
            return;
        }
        if (this.state.submissionType === 'richText' && (!this.state.ArticleText || this.state.ArticleText === '')) {
            this.setState({error: 'লেখাটি বাংলায় লিখে ফ্লপি আইকনে ক্লিক করে সুরক্ষিত করে জমা করুন'});
            this.setState({processing: false});
            return;
        }

        this.setState({error: null});

        //upload file
        const formData = new FormData();
        //console.log('this.state.ArticleText=', this.state.ArticleText)
        const SubmissionId = this.createID();
        //
        // Update the formData object
        if (this.state.selectedFile) {
            formData.append(
                "doc",
                this.state.selectedFile,
                (this.state.selectedFile as any)?.name,
            );
        }
        formData.append(
            "Category",
            this.state.Category,
        );
        formData.append(
            "SubmissionId",
            SubmissionId,
        );
        formData.append(
            "ArticleText",
            this.state.ArticleText,
        );
        formData.append(
            "ArticleNameInBengali",
            this.state.ArticleNameInBengali,
        );
        formData.append(
            "AuthorEmail",
            this.state.AuthorEmail,
        );
        formData.append(
            "AuthorNameInBengali",
            this.state.AuthorNameInBengali,
        );
        const headers = {
            'Authorization': 'Basic dWRiaGFzOkJlbmdhbGlMaXRAMjAyMA==',
            'Content-Type': 'multipart/form-data',
        };
        const uploadResult = await axios.post(`${API_BASE}${GDRIVE_API_PATH}`, formData, {headers} )
        .catch(function (error) {
            //console.log(error);
            toast.error(error.toString());
            self.setState({processing: null});
        });
        //const uploadResultJson = JSON.parse(uploadResult) || {};
        if (!uploadResult) {
            toast.error(`Error connectine to ${API_BASE}`);
            self.setState({processing: null});
            return;
        }
        if (!uploadResult.data.success) {
            if (uploadResult?.data?.SubmissionId) {
                this.setState({error: `এই লেখাটি দ্বিতীয়বার জমা করা হচ্ছে, সাবমিশন আইডি: ${uploadResult?.data?.SubmissionId}`});
            } else {
                this.setState({error: 'File upload error'});
            }
            self.setState({processing: null});
            return;
        }
        const { id } = uploadResult.data.data;

        const fileUrl = `${GDRIVE_BASE}${id}/view`;
        
        const phone = this.state.AuthorPhone;
        const data = JSON.stringify({
            data: {
                Timestamp: new Date().toString(),
                AuthorEmail: this.state.AuthorEmail,
                AuthorNameInBengali: this.state.AuthorNameInBengali,
                SubmissionId: SubmissionId,
                Category: this.state.Category,
                ArticleNameInBengali: this.state.ArticleNameInBengali,
                ArticleDescription: this.state.ArticleDescription,
                AuthorPhone: (phone && (phone as String)?.length > 0 && (phone as String).replace('+', '')) || '',
                AuthorAddressInEnglish: this.state.AuthorAddressInEnglish,
                ArticleText: this.state.ArticleText,
                ArticleUrl: fileUrl,
                MarketingSource: this.state.MarketingSource,
                MarketingSourceDetails: this.state.MarketingSourceDetails,
            }
        });

        const config = {
            method: 'post',
            url: `${API_BASE}${GSHEET_API_PATH}`,
            headers: {
                Authorization: 'Basic dWRiaGFzOkJlbmdhbGlMaXRAMjAyMA==',
                'Content-Type': "application/json",
            },
            data,
        };

        const updateResult = await axios(config)
        .catch(function (error) {
            //console.log(error);
            toast.error(error.toString());
            self.setState({processing: null});
        });
        //console.log(updateResult.data);
        if (!updateResult?.data.success) {
            this.setState({error: "Form submission error, try again!"})
            self.setState({processing: null})
        } else {
            formData.append(
                "subject",`Article Submission(${SubmissionId})`,
            );
            formData.append(
                "text", `উদ্ভাসকে লেখা ('${this.state.ArticleNameInBengali}') ('${this.state.Category}') পাঠানোর জন্য ধন্যবাদ। আপনি লেখা জমা করার ফর্মের অনুসন্ধান ট্যাব থেকে লেখাটির বর্তমান পরিস্থিতি জানতে পারেন ~ উদ্ভাস।`,
            );
            const emailResult = await axios.post(`${API_BASE}sendEmail`, formData, {headers} )
            console.log('emailResult=', emailResult)
            if (emailResult) {
                //console.log(emailResult)
                toast.success(`${this.state.AuthorEmail}`)
            }
            const success = `আপনার লেখাটি ('${this.state.ArticleNameInBengali}') জমা পড়েছে (submission id: ${SubmissionId})`
            this.reset();
            this.setState({success});
        }
        this.setState({processing: null});
    }

    render() {
        //TODO: add your form fields below that you want to submit to the sheet. This just has a name field
        return (
            <ValidatorForm onSubmit={this.onFormSubmit} color="primary.main" style={{width: 600}}>
                <div >
                    <FormLabel><h1 style={{fontFamily: "Shonar Bangla"}}>উদ্ভাসে লেখা জমা করার ফর্ম</h1></FormLabel>
                    <FormLabel style={{width: 400}}><h4 style={{width: 400, fontFamily: "Shonar Bangla"}}>এই ফর্মটিতে কিছু তথ্য এবং জমা করার লেখাটি বাংলায় লিখতে হবে। ল্যাপটপে
                        <a target ="_blank" href="https://www.google.com/intl/bn/inputtools/try/"> গুগল ইনপুট টুল </a>
                        অথবা<a target ="_blank" href="https://chrome.google.com/webstore/detail/google-input-tools/mclkkofklkfljcocdinagocijmpgbhab?hl=bn"> প্লাগইন </a>
                        এবং মোবাইলে বাংলা কীবোর্ড ব্যবহার করুন। </h4>
                    </FormLabel>
                    <hr style={{minWidth: 400}}/>
                </div>
                <div>
                    <FormLabel htmlFor="AuthorNameInBengali"><h1 style={{fontFamily: "Shonar Bangla"}}>লেখকের নাম&#x2a;</h1></FormLabel>
                    <TextValidator
                        required
                        style={{minWidth: 400}}
                        label="লেখকের নামটি বাংলা হরফে লিখুন"
                        name="AuthorNameInBengali"
                        value={this.state.AuthorNameInBengali||''}
                        validators={['required']}
                        onChange={this.handleAuthorNameInBengali}
                        errorMessages={['এই তথ্যটি প্রয়োজন', 'নামটি ঠিক করে টাইপ করুন']}
                    />
                </div>

                <div>
                    <FormLabel htmlFor="AuthorEmail"><h1>Email&#x2a;</h1></FormLabel>
                    <TextValidator
                        required
                        style={{minWidth: 400}}
                        label="Please write author's email address"
                        name="AuthorEmail"
                        value={this.state.AuthorEmail || ''}
                        validators={['required', 'isEmail']}
                        onChange={this.handleAuthorEmail}
                        errorMessages={['Email is required', "Please write author's email address"]}
                    />
                    <FormHelperText id="my-helper-text">This will not be shared.</FormHelperText>
                </div>


                <div>
                    <FormLabel htmlFor="AuthorPhone"><h1>Author's Phone (optional)</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <MuiPhoneNumber id='AuthorPhone'
                                        defaultCountry={'in'}
                                        value={this.state.AuthorPhone || ''}
                                        onChange={e => this.handleAuthorPhone(e)
                                        }
                        />
                        <FormHelperText id="my-helper-text">This will not be shared.</FormHelperText>
                    </FormControl>
                </div>

                <div>
                    <FormLabel htmlFor="AuthorAddresInEnglish"><h1>Address (optional)</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextField id="AuthorAddresInEnglish"
                                   value={this.state.AuthorAddressInEnglish || ''}
                                   onChange={this.handleAuthorAddressInEnglish}
                                   multiline
                                   aria-describedby="Please write author's address in English alphabet." />
                        <FormHelperText id="my-helper-text">Please write author's address in English alphabet. This will not be shared.</FormHelperText>
                    </FormControl>
                </div>

                <div>
                    <FormLabel htmlFor="ArticleNameInBengali"><h1 style={{fontFamily: "Shonar Bangla"}}>লেখার নাম&#x2a;</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextValidator
                            required
                            validators={['required']}
                            style={{minWidth: 400}}
                            label="লেখার নামটি বাংলা হরফে লিখুন"
                            onChange={this.handleArticleNameInBengali}
                            value={this.state.ArticleNameInBengali || ''}
                            id="ArticleNameInBengali"
                            errorMessages={['এই তথ্যটি প্রয়োজন', 'নামটি ঠিক করে টাইপ করুন']}
                            />
                    </FormControl>
                </div>

                <div>
                    <FormLabel style={{minWidth: 400, textAlign: 'justify'}} htmlFor="Category"><h1 style={{fontFamily: "Shonar Bangla"}}>বিভাগ&#x2a;</h1></FormLabel>
                </div>

                <div>
                    <FormControl style={{minWidth: 400}}>
                        <NativeSelect
                            required
                            style={{minWidth: "400px"}}
                            name="Category" id="Category"
                            onChange={this.onCategoryChange}
                            value={this.state.Category||' '}>
                            <option value=""> </option>
                            <option value="poem">কবিতা</option>
                            <option value="shonibarerkobita">শনিবারের কবিতা</option>
                            <option value="chhotoderchhora">ছোটোদের ছড়া</option>
                            <option value="shortstory">ছোটোগল্প</option>
                            <option value="microstory">অণুগল্প</option>
                            <option value="essay">প্রবন্ধ</option>
                            <option value="prose">মুক্ত গদ্য</option>
                            <option value="travelogue">ভ্রমণকাহিনি</option>
                            <option value="novel">উপন্যাস</option>
                            <option value="novella">উপন্যাসিকা</option>
                            <option value="robibarergolpo">রবিবারের গল্প</option>
                            <option value="chhotodergolpo">ছোটোদের গল্প</option>
                            <option value="romyorochona">রম্যরচনা</option>
                            <option value="other">চিঠিপত্র এবং অন্যান্য</option>
                        </NativeSelect>
                    </FormControl>
                </div>

                <div>
                    <FormLabel htmlFor="ArticleDescription"><h1 style={{fontFamily: "Shonar Bangla"}}>অন্যান্য কিছু তথ্য (ঐচ্ছিক)</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextField rows="5"
                                   value={this.state.ArticleDescription || ''}
                                   onChange={this.handleArticleDescription}
                                   id="AuthorAddresInEnglish"
                                   multiline aria-describedby="আপনার লেখাটি সম্পর্কে অন্যান্য কিছু তথ্য (ঐচ্ছিক)"
                        />
                        <FormHelperText style={{fontFamily: "Shonar Bangla"}} id="my-helper-text">আপনার লেখাটি সম্পর্কে অন্যান্য কিছু তথ্য (ঐচ্ছিক)</FormHelperText>
                    </FormControl>
                </div>
                <div style={{height: 10}}></div>
                <div style={{width: 400}}>
                    <Card>
                        <CardPrimaryContent>
                            <span style={{fontFamily: "Shonar Bangla", fontSize: "1.5em"}}>আপনার লেখা মৌলিক গল্পটি আপলোড অথবা টাইপ করুন।
                                অণুগল্পের শব্দসংখ্যা: ২০০ থেকে ৫০০, মুক্ত গদ্যের শব্দ সংখ্যা ১০০০ থেকে ৩০০০, প্রবন্ধ, রম্যরচনা,
                                ছোটগল্প এবং ভ্রমণকাহিনির শব্দসংখ্যা: ২০০০ থেকে ৩০০০, রবিবারের গল্পের শব্দসংখ্যা: ১০০০ থেকে ১৫০০,
                                উপন্যাসিকার শব্দসংখ্যা ৭০০০ থেকে ১০০০০, উপন্যাসের শব্দসংখ্যা: ২০০০০ থেকে ৫০০০০।
                                <strong> লেখার ভেতরে লেখকের নাম অথবা ছবি থাকলে লেখা বাতিল করা হবে।</strong> লেখা জমা করার পর আপনি একটি ইমেইল পাবেন,
                                সেখানে একটি সাবমিশন আইডি'র উল্লেখ থাকবে। সাবমিশন আইডি এবং লেখার বিভাগ দিয়ে এই ফর্মের অনুসন্ধান ট্যাব থেকে আপনি আপনার লেখাটির বর্তমান পরিস্থিতির কথা জানতে পারবেন।
                                অনুগ্রহ করে কোনও লেখা দ্বিতীয় বার জমা করবেন না। লেখায় ছোটোখাটো অসঙ্গতি থাকলে লেখককে যোগাযোগ করে লেখাটি সংশোধনের সুযোগ দেওয়া হবে।
                            </span>
                            <span style={{fontFamily: "Shonar Bangla", fontSize: "2em"}}>
                                <strong>
আপনার লেখাটি মনোনীত হয়ে উদ্ভাস পত্রিকায় প্রকাশিত হওয়ার পর প্রকাশ তারিখের ছয় মাসের মধ্যে সামাজিক অন্তর্জালের মাধ্যমে পাঠকের জন্য উন্মুক্ত করবেন না। ছয় মাস পর উন্মুক্ত অবস্থাতে লেখাটিতে উদ্ভাস পত্রিকার কথা উল্লেখ করবেন। উদ্ভাসের সম্মতি ব্যতিরেকে লেখাটি মুদ্রিত আকারে প্রকাশ করবেন না।</strong>
                            </span>
                        </CardPrimaryContent>
                    </Card>
                </div>
                <div style={{height: 10}}></div>
                <div>
                    {!this.state.selectedFile && <FormControl>
                        <FormLabel><h1 style={{fontFamily: "Shonar Bangla"}}>জমা করার পদ্ধতি</h1></FormLabel>
                        <RadioGroup aria-label="submission" name="submissionOption" value={this.state.submissionType}
                                    onChange={(e) => this.handleSubmissionOption(e)}>
                            <FormControlLabel style={{fontFamily: "Shonar Bangla"}} value="file" control={<Radio/>} label="ফাইল আপলোড করুন"/>
                            <FormControlLabel style={{fontFamily: "Shonar Bangla"}} value="text" control={<Radio/>} label="এখানে লিখুন অথবা কপি/পেস্ট করুন"/>
                        </RadioGroup>
                    </FormControl>
                    }
                </div>
                {this.state.submissionType === 'file' &&
                    <div style={{marginTop: 20}}>
                        <FormControl style={{minWidth: 400}}>
                            <Button
                                variant="contained"
                                component="label"
                            >
                                Add File
                                <input
                                    type="file"
                                    accept=".txt,.rtf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    hidden
                                    key={this.state.selectedFileName || '' }
                                value={this.state.selectedFileName}
                                    onChange={this.onFileChange}
                                />
                            </Button>
                        </FormControl>
                    </div>
                }
                {this.state.submissionType === 'text' &&
                <div style={{minHeight: 400, maxWidth: 600}}>
                    <TextareaAutosize
                        placeholder="বাংলা হরফে লিখে অথবা ক্লিপবোর্ডে কপি করে এখানে সেঁটে দিন"
                        onChange={this.handleText}
                        rows={20}
                        style={{ width: 400 }}
                    />,
                </div>
                }
                {this.state.submissionType === 'richText' &&
                    <div style={{minHeight: 400, maxWidth: 600}}>
                        <MUIRichTextEditor
                            onSave={this.handleRicheText}
                            label="বাংলা হরফে লিখে অথবা অন্য কোথাও থেকে ক্লিপবোর্ডে কপি করে এখানে সেঁটে দিয়ে ফ্লপি আইকনে ক্লিক করে লেখাটি সুরক্ষিত করুন..."
                        />,
                    </div>
                }
                <div>
                    {this.fileData()}
                </div>
                <div>
                    <FormLabel htmlFor="Source"><h1 style={{fontFamily: "Shonar Bangla"}}>প্রথম পরিচয় (ঐচ্ছিক)</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <NativeSelect
                            style={{minWidth: "400px"}}
                            name="MarketingSource" id="MarketingSource"
                            onChange={this.handleMarketingSource}
                            value={this.state.MarketingSource || ' '}>
                            <option value=""> </option>
                            <option value="facebook">ফেসবুক নিউজ ফিড</option>
                            <option value="fbgroup">ফেসবুক গ্ৰুপ</option>
                            <option value="fbpage">ফেসবুক পেজ</option>
                            <option value="whatsapp">হোয়াটসএপ</option>
                            <option value="google">গুগল সার্চ</option>
                            <option value="invitattion">উদ্ভাসের আমন্ত্রণ</option>
                            <option value="wordofmouth">মুখের কথা</option>
                            <option value="other">অন্যান্য</option>
                        </NativeSelect>
                        <FormHelperText style={{fontFamily: "Shonar Bangla", width: 400}} id="my-helper-text">উদ্ভাসের সঙ্গে প্রথম পরিচয় কীভাবে হলো? উদ্ভাসের সঙ্গে আলাপের কথা সংক্ষেপে লিখুন, ফেসবুকে না লোকমুখে না অন্য কোথাও..."</FormHelperText>
                    </FormControl>
                    <FormControl style={{minWidth: 400}}>
                        <TextField id="MarketingSourceDetails"
                                   value={this.state.MarketingSourceDetails || ''}
                                   onChange={this.handleMarketingSourceDetails}
                                   multiline
                                   aria-describedby="বিস্তারিত ভাবে প্রথম পরিচয়ের কথা লিখুন" />
                        <FormHelperText style={{fontFamily: "Shonar Bangla", width: 400}} id="my-helper-text">বিস্তারিত ভাবে প্রথম পরিচয়ের কথা লিখুন</FormHelperText>
                    </FormControl>
                </div>
                <div style={{marginTop: 20, maxWidth: 400}}>
                    <span style={{fontFamily: "Shonar Bangla"}}>* প্রয়োজনীয় তথ্য</span>
                    {(this.state.success || this.state.error) &&
                    <Card>
                        <CardPrimaryContent>
                            <span>{this.state.success}</span>
                            <span>{this.state.error}</span>
                        </CardPrimaryContent>
                    </Card>
                    }
                </div>
                <div style={{marginTop: 20}}>
                    {this.state.processing ?
                        <FormLabel><h1>Processing...<CircularProgress style={{verticalAlign: "middle"}}/></h1></FormLabel>
                        : <Button variant="contained" color="primary" style={{minWidth: 400, fontFamily: "Shonar Bangla"}}
                                  type='submit'>জমা করুন</Button>
                    }
                </div>
            </ValidatorForm>
    )
    }
}
