import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SubmitForm from "./googleSheet/submitForm";
import QueryForm from "./googleSheet/queryForm";
import logo from './images/udvas_logo_transparent_large.png';
import ProfileForm from "./googleSheet/profileForm";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}
export default function App() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <AppBar position="static" alignItems="center">
                <Tabs value={value} onChange={handleChange}>
                    <Tab style={{fontFamily: "Shonar Bangla", fontSize: "1.5em"}} label="লেখা জমা" />
                    <Tab style={{fontFamily: "Shonar Bangla", fontSize: "1.5em"}} label="অনুসন্ধান" />
                    <Tab style={{fontFamily: "Shonar Bangla", fontSize: "1.5em"}} label="ব্যাংকের তথ্য" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <img src={logo} style={{width: 200}}/>
                <SubmitForm/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <img src={logo} style={{width: 200}}/>
                <QueryForm/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <img src={logo} style={{width: 200}}/>
                <ProfileForm/>
            </TabPanel>
        </>
    );
}
