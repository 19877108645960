// import { Form, Text } from 'informed'; //https://joepuzzo.github.io/informed/
import React from 'react';
import ToastServive from 'react-material-toast';

import {
    Button,
    FormControl,
    FormHelperText,
    TextField,
    FormLabel,
    CircularProgress, NativeSelect,
} from '@material-ui/core';
import Card, {
    CardPrimaryContent,
    CardActions,
    CardActionButtons,
} from "@material/react-card";
import '@material/react-card/dist/card.css';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { isBengali } from './submitForm';


const axios = require('axios');
const FormData = require('form-data');


//const API_BASE = 'http://localhost:3000/';
const API_BASE = 'https://potrika.ue.r.appspot.com/';
const GDRIVE_API_PATH='gdrive/author/profile/photo/add/';
const GSHEET_API_PATH='gsheet/author/add/';
const GDRIVE_BASE='https://drive.google.com/file/d/';

const toast = ToastServive.new({
    place:'topRight',
    duration:2,
    maxCount:8
});
const initialState = {
    // Initially, no file is selected
    selectedPhoto: '',
    AuthorEmail: null,
    AuthorNameInEnglish: null,
    AuthorAddressInEnglish: null,
    AuthorPhone: null,
    AuthorBio: '',
    error: null,
    success: null,
    processing: null,
    BankNameInEnglish: '',
    BankAddressInEnglish: '',
    BankAccountNumber: '',
    BankCode: '',
    selectedFileName: '',
    PaymentType: 'bank',
    PaymentAddress: ''
};


export default class ProfileForm extends React.Component {
    state = initialState;


    constructor(props) {
        super(props);
        console.log('in profile form');
        this.onFormSubmit = this.onFormSubmit.bind(this); //to tie the form's callback to this class
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount(){ //called automatically by React
    }
    componentWillUnmount() {
    }
    onDrop = (event) =>{
        if (event) {
            this.setState({
                selectedPhoto: event.target.files[0],
            });
            console.log(this.state.selectedPhoto)
        }
    }
    reset = () => {
        this.setState(initialState);
        //console.log('reset called', this.state)
    }
    handleClientLoad = async ()=> { //initialize the Google API
    }

    initClient =()=> { //provide the authentication credentials you set up in the Google developer console
    }
    handleAuthorNameInEnglish = async (e) => {
        this.setState({AuthorNameInEnglish: e.target.value && e.target.value.trim().length && e.target.value})
    }
    handleAuthorEmail = async (e) => {
        this.setState({AuthorEmail: e.target.value && e.target.value.trim().length && e.target.value})
    }
    handleAuthorPhone = async (val) => {
        this.setState({AuthorPhone: val})
    }
    handleAuthorAddressInEnglish = async (e) => {
        this.setState({AuthorAddressInEnglish: e.target.value})
    }
    handleAuthorBio = async (e) => {
        this.setState({AuthorBio: (e.target.value && e.target.value.trim().length
                && isBengali(e.target.value) && e.target.value)
                || null})
    }
    handleBankNameInEnglish = async (e) => {
        this.setState({BankNameInEnglish: e.target.value})
    }
    handleBankAddressInEnglish = async (e) => {
        this.setState({BankAddressInEnglish: e.target.value})
    }
    handleBankAccountNumber = async (e) => {
        this.setState({BankAccountNumber: e.target.value})
    }
    handleBankCode = async (e) => {
        this.setState({BankCode: e.target.value})
    }
    onRemovePhoto = event => {
        this.setState({ selectedPhoto: null });
    }

    handlePaymentTypeChange = async (e) => {
        this.setState({ PaymentType: e.target.value });
    }

    handlePaymentAddress = async (e) => {
        this.setState({ PaymentAddress: e.target.value });
    }

    // File content to be displayed after
    // file upload is complete
    fileData = () => {
        if (this.state.selectedPhoto) {
            const photoUrl = (this.state.selectedPhoto)? URL.createObjectURL(this.state.selectedPhoto) : ''
            return (
                <Card>
                    <CardPrimaryContent>
                        <img src={photoUrl}/>
                        <span>Name: {(this.state.selectedPhoto as any).name}</span>
                        <span>File Size: {(this.state.selectedPhoto as any).size} bytes</span>
                        <span>Last Modified: {(this.state.selectedPhoto as any).lastModifiedDate?.toDateString()}</span>
                    </CardPrimaryContent>
                    <CardActions>
                        <CardActionButtons>
                            <button onClick={this.onRemovePhoto}>X</button>
                        </CardActionButtons>
                    </CardActions>
                </Card>
            );
        } else {
            return null;
        }
    };
    onFormSubmit = async (values) => {
        const self = this;
        this.setState({processing: true});
        this.setState({success: true});
        this.setState({error: true});
        this.setState({error: null});

        //upload file
        const formData = new FormData();
        //console.log('this.state.ArticleText=', this.state.ArticleText)
        //
        // Update the formData object
        if (this.state.selectedPhoto) {
            formData.append(
                "doc",
                this.state.selectedPhoto,
                (this.state.selectedPhoto as any)?.name,
            );
        }

        formData.append(
            "AuthorEmail",
            this.state.AuthorEmail,
        );

        formData.append(
            "AuthorNameInEnglish",
            this.state.AuthorNameInEnglish,
        );

        const headers = {
            'Authorization': 'Basic dWRiaGFzOkJlbmdhbGlMaXRAMjAyMA==',
            'Content-Type': 'multipart/form-data',
        };
        const uploadResult = await axios.post(`${API_BASE}${GDRIVE_API_PATH}`, formData, {headers} )
            .catch(function (error) {
                //console.log(error);
                toast.error(error.toString());
                self.setState({processing: null});
            });
        //const uploadResultJson = JSON.parse(uploadResult) || {};
        if (!uploadResult) {
            toast.error(`Error connectine to ${API_BASE}`);
            self.setState({processing: null});
            return;
        }
        if (!uploadResult.data.success) {
            this.setState({error: 'File upload error'});
            self.setState({processing: null});
            return;
        }
        const { id } = uploadResult.data.data;

        const fileUrl = `${GDRIVE_BASE}${id}/view`;

        const phone = this.state.AuthorPhone;

        const data = JSON.stringify({
            data: {
                Timestamp: new Date().toString(),
                AuthorNameInEnglish: this.state.AuthorNameInEnglish,
                AuthorAddressInEnglish: this.state.AuthorAddressInEnglish,
                AuthorEmail: this.state.AuthorEmail,
                AuthorPhone: (phone && (phone as String)?.length > 0 && (phone as String).replace('+', '')) || '',
                AuthorPhoto: fileUrl,
                AuthorBio: this.state.AuthorBio,
                BankName: this.state.BankNameInEnglish,
                BankAddressInEnglish: this.state.BankAddressInEnglish,
                BankAccountNumber: `'${this.state.BankAccountNumber}`,
                BankCode: `'${this.state.BankCode}`,
                PaymentType: `'${this.state.PaymentType}`,
                PaymentAddress: `'${this.state.PaymentAddress}`,
            }
        });

        const config = {
            method: 'post',
            url: `${API_BASE}${GSHEET_API_PATH}`,
            headers: {
                Authorization: 'Basic dWRiaGFzOkJlbmdhbGlMaXRAMjAyMA==',
                'Content-Type': "application/json",
            },
            data,
        };

        const updateResult = await axios(config)
            .catch(function (error) {
                //console.log(error);
                toast.error(error.toString());
                self.setState({processing: null});
            });
        //console.log(updateResult.data);
        if (!updateResult?.data.success) {
            this.setState({error: "Form submission error, try again!"})
            self.setState({processing: null})
        } else {
            formData.append(
                "subject",`Bank information submission`,
            );
            formData.append(
                "AuthorEmail", this.state.AuthorEmail,
            );
            formData.append(
                "AuthorNameInBengali", this.state.AuthorNameInEnglish,
            );
            formData.append(
                "text", `উদ্ভাসকে তথ্য পাঠানোর জন্য ধন্যবাদ।
 Name: ${this.state.AuthorNameInEnglish},
 Short Bio: ${this.state.AuthorBio},
 Bank: ${this.state.BankNameInEnglish},
 Account Number: ${this.state.BankAccountNumber},
 Code: ${this.state.BankCode},
 Address: ${this.state.BankAddressInEnglish},
 PaymentType: ${this.state.PaymentType},               
 PaymentAddress: ${this.state.PaymentAddress}`,
            );
            const emailResult = await axios.post(`${API_BASE}sendEmail`, formData, {headers} )
            if (emailResult) {
                //console.log(emailResult)
                toast.success(`${this.state.AuthorEmail}`)
            }
            const success = ''
            this.reset();
            this.setState({success});
        }
        this.setState({processing: null});
    }

    render() {
        //TODO: add your form fields below that you want to submit to the sheet. This just has a name field
        return (
            <ValidatorForm onSubmit={this.onFormSubmit} color="primary.main" style={{width: 600}}>
                <div >
                    <FormLabel><h1 style={{fontFamily: "Shonar Bangla"}}>ব্যাংকের তথ্য জমা করার ফর্ম</h1></FormLabel>
                    <hr style={{minWidth: 400}}/>
                </div>
                <div>
                    <FormLabel htmlFor="AuthorNameInEnglish"><h1>Author's Name&#x2a;</h1></FormLabel>
                    <TextValidator
                        required
                        style={{minWidth: 400}}
                        label="Autthor's name in English"
                        name="AuthorNameInEnglish"
                        value={this.state.AuthorNameInEnglish||''}
                        validators={['required']}
                        onChange={this.handleAuthorNameInEnglish}
                        errorMessages={['Name is required', "Please write author's name"]}
                    />
                </div>
                <div>
                    <FormLabel htmlFor="AuthorEmail"><h1>Email&#x2a;</h1></FormLabel>
                    <TextValidator
                        required
                        style={{minWidth: 400}}
                        label="Please write author's email address"
                        name="AuthorEmail"
                        value={this.state.AuthorEmail || ''}
                        validators={['required', 'isEmail']}
                        onChange={this.handleAuthorEmail}
                        errorMessages={['Email is required', "Please write author's email address"]}
                    />
                    <FormHelperText id="my-helper-text">This will not be shared.</FormHelperText>
                </div>


                <div>
                    <FormLabel htmlFor="AuthorPhone"><h1>Author's Phone&#x2a;</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <MuiPhoneNumber id='AuthorPhone'
                                        defaultCountry={'in'}
                                        value={this.state.AuthorPhone || ''}
                                        onChange={e => this.handleAuthorPhone(e)
                                        }
                        />
                        <FormHelperText id="my-helper-text">This will not be shared.</FormHelperText>
                    </FormControl>
                </div>

                <div>
                    <FormLabel htmlFor="AuthorAddresInEnglish"><h1>Address&#x2a;</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextValidator id="AuthorAddresInEnglish"
                                   required
                                   value={this.state.AuthorAddressInEnglish || ''}
                                   onChange={this.handleAuthorAddressInEnglish}
                                   multiline
                                   aria-describedby="Please write author's address in English alphabet." />
                        <FormHelperText id="my-helper-text">Please write author's address in English alphabet. This will not be shared.</FormHelperText>
                    </FormControl>
                </div>

                <div>
                    <FormLabel htmlFor="AuthorBio"><h1 style={{fontFamily: "Shonar Bangla"}}>বাংলা হরফে লেখকের সম্পর্কে কিছু কথা লিখুন</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextField rows="10"
                                   value={this.state.AuthorBio || ''}
                                   onChange={this.handleAuthorBio}
                                   id="AuthorBio"
                                   multiline aria-describedby="লবাংলা হরফে লেখকের সম্পর্কে কিছু কথা লিখুন"
                        />
                        <FormHelperText style={{fontFamily: "Shonar Bangla"}} id="my-helper-text">লবাংলা হরফে লেখকের সম্পর্কে কিছু কথা লিখুন</FormHelperText>
                    </FormControl>
                </div>
                <div>
                    <FormLabel htmlFor="AuthorBio"><h1 style={{fontFamily: "Shonar Bangla"}}>কীভাবে সাম্মানিক গ্রহণ করতে চান</h1></FormLabel>
                        <FormControl style={{minWidth: 400}}>
                            <NativeSelect
                                required
                                style={{minWidth: "400px"}}
                                name="Category" id="Category"
                                onChange={this.handlePaymentTypeChange}
                                value={this.state.PaymentType}>
                                <option value="bank">Bank Deposit</option>
                                <option value="paytm">PayTm</option>
                                <option value="gpay">GPay</option>
                                <option value="phonepe">PhonePe</option>
                                <option value="paypal">PayPal</option>
                                <option value="zelle">Zelle</option>
                                <option value="other">Other</option>
                            </NativeSelect>
                        </FormControl>
                </div>

                {this.state.PaymentType === 'bank' && <div>
                    <FormLabel htmlFor="BankNameInEnglish"><h1>Bank Name&#x2a;</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextValidator id="BankNameInEnglish"
                                   required
                                   value={this.state.BankNameInEnglish || ''}
                                   onChange={this.handleBankNameInEnglish}
                                   multiline
                                   aria-describedby="Please write author's bank name in English alphabet." />
                        <FormHelperText id="my-helper-text">Please write bank name in English alphabet. This will not be shared.</FormHelperText>
                    </FormControl>
                </div>
                }

                {this.state.PaymentType === 'bank' && <div>
                    <FormLabel htmlFor="BankAddressInEnglish"><h1>Bank Address&#x2a;</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextValidator id="BankAddressInEnglish"
                                       required
                                       value={this.state.BankAddressInEnglish || ''}
                                       onChange={this.handleBankAddressInEnglish}
                                       multiline
                                       aria-describedby="Please write author's bank address in English alphabet."/>
                        <FormHelperText id="my-helper-text">Please write bank address in English alphabet. This will not
                            be shared.</FormHelperText>
                    </FormControl>
                </div>
                }

                {this.state.PaymentType === 'bank' && <div>
                    <FormLabel htmlFor="BankAccountNumber"><h1>Account Number&#x2a;</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextValidator id="BankAccountNumber"
                                   required
                                   value={this.state.BankAccountNumber || ''}
                                   onChange={this.handleBankAccountNumber}
                                   multiline
                                   validators={['matchRegexp:^[0-9]*$']}
                                   aria-describedby="Please write author's bank account number." />
                        <FormHelperText id="my-helper-text">Please write bank account number. This will not be shared.</FormHelperText>
                    </FormControl>
                </div>
                }

                {this.state.PaymentType === 'bank' && <div>
                    <FormLabel htmlFor="BankCode"><h1>Bank Code (for Indian Bank it is IIFC code, for US bank routing
                        number, for other countries SWIFT code)&#x2a;</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextValidator id="BankIIFCCode"
                                       required
                                       value={this.state.BankCode || ''}
                                       onChange={this.handleBankCode}
                                       multiline
                                       validators={['matchRegexp:^[A-Za-z0-9]*$']}
                                       aria-describedby="Please write author's bank code."/>
                        <FormHelperText id="my-helper-text">Please write bank code. This will not be
                            shared.</FormHelperText>
                    </FormControl>
                </div>
                }

                {this.state.PaymentType !== 'bank' && <div>
                    <FormLabel htmlFor="PaymentAddress"><h1>Payment address for (PayTm, GPay, PayPal, Zelle etc.)&#x2a;</h1></FormLabel>
                    <FormControl style={{minWidth: 400}}>
                        <TextValidator id="PaymentAddress"
                                       required
                                       value={this.state.PaymentAddress || ''}
                                       onChange={this.handlePaymentAddress}
                                       multiline
                                       validators={['matchRegexp:[.]*@[.]*']}
                                       aria-describedby="Please write author's gateway payment address like 123456789@paytm, paypalemail@gmail.com etc."/>
                        <FormHelperText id="my-helper-text">Please write payment address like 123456789@paytm, paypalemail@gmail.com etc. This will not be
                            shared.</FormHelperText>
                    </FormControl>
                </div>
                }
                <div style={{height: 10}}></div>

                <div style={{marginTop: 20, maxWidth: 400}}>
                    <span style={{fontFamily: "Shonar Bangla"}}>* প্রয়োজনীয় তথ্য</span>
                    {(this.state.success || this.state.error) &&
                    <Card>
                        <CardPrimaryContent>
                            <span>{this.state.success}</span>
                            <span>{this.state.error}</span>
                        </CardPrimaryContent>
                    </Card>
                    }
                </div>
                <div>
                    <div>
                        <FormLabel htmlFor="SelectedPhoto"><h1>Please upload a passport-size photo</h1></FormLabel>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            Upload photo
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                key={this.state.selectedFileName || '' }
                                value={this.state.selectedFileName}
                                onChange={this.onDrop}
                            />
                        </Button>
                    </div>
                </div>
                <div>
                    {this.fileData()}
                </div>
                <div style={{marginTop: 20}}>
                    {this.state.processing ?
                        <FormLabel><h1>Processing...<CircularProgress style={{verticalAlign: "middle"}}/></h1></FormLabel>
                        : <Button variant="contained" color="primary" style={{minWidth: 400, fontFamily: "Shonar Bangla"}}
                                  type='submit'>জমা করুন</Button>
                    }
                </div>
            </ValidatorForm>
        )
    }
}
