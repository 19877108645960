import React from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {Button, CircularProgress, FormControl, FormLabel, NativeSelect, Checkbox} from "@material-ui/core";
import Card, {CardPrimaryContent} from "@material/react-card";
import ToastServive from 'react-material-toast';

const axios = require('axios');
//const API_BASE = 'http://localhost:3000/';
const API_BASE = 'https://potrika.ue.r.appspot.com/';
const GDRIVE_API_PATH='gdrive/copyright/add';
const GSHEET_API_PATH='gsheet/submissions/query';
const GDRIVE_BASE='https://drive.google.com/file/d/';

const initialState = {
    Category: null,
    SubmissionId: null,
    processing: null,
    error: null,
    status: null,
    ArticleNameInBengali: null,
    AuthorNameInBengali: null,
    AuthorEmail: null,
    Timestamp: null,
    copyrightProcessing: null,
    copyrightError: null,
    copyrightStatus: false,
    copyrightSubmitted: false,
    copyrightSuccess: null,
};
/*
  <option value="poem">কবিতা</option>
                            <option value="shonibarerkobita">শনিবারের কবিতা</option>
                            <option value="chhotoderchhora">ছোটোদের ছড়া</option>
                            <option value="shortstory">ছোটোগল্প</option>
                            <option value="microstory">অণুগল্প</option>
                            <option value="essay">প্রবন্ধ</option>
                            <option value="prose">মুক্ত গদ্য</option>
                            <option value="travelogue">ভ্রমণকাহিনি</option>
                            <option value="novel">উপন্যাস</option>
                            <option value="novella">উপন্যাসিকা</option>
                            <option value="robibarergolpo">রবিবারের গল্প</option>
                            <option value="chhotodergolpo">ছোটোদের গল্প</option>
                            <option value="romyorochona">রম্যরচনা</option>
                            <option value="other">চিঠিপত্র এবং অন্যান্য</option>
 */
const categoryMap = {
    poem: 'কবিতা',
    microstory: 'অণুগল্প',
    shortstory: 'ছোটগল্প',
    novel: 'উপন্যাস',
    essay: 'প্রবন্ধ',
    travelogue: 'ভ্রমণকাহিনি',
    other: 'চিঠিপত্র এবং অন্যান্য',
    shonibarerkobita: 'শনিবারের কবিতা',
    chhotoderchhora: 'ছোটোদের ছড়',
    prose: 'মুক্তগদ্য',
    robibarergolpo: 'রবিবারের গল্প',
    chhotodergolpo: 'ছোটোদের গল্প',
    romyorochona: 'রম্যরচনা',
};
const toast = ToastServive.new({
    place:'topRight',
    duration:2,
    maxCount:8
});
export default class QueryForm extends React.Component {
    state = initialState;

    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this); //to tie the form's callback to this class
        this.onCopyrightFormSubmit = this.onCopyrightFormSubmit.bind(this); //to tie the form's callback to this class
    }

    componentDidMount() { //called automatically by React

    }

    componentWillUnmount() {
        // remove rule when it is not needed
    }
    onCategoryChange = event => {
        this.setState({ Category: event.target.value});
    }
    handleSubmissionIdChange = async (e) => {
        this.setState({SubmissionId: e.target.value})
    }
    reset = () => {
        this.setState(initialState);
    }
    copyrightReset = () => {
        this.setState({
            copyrightProcessing: false,
            copyrightError: null,
            copyrightSuccess: null,
            copyrightStatus: false,
            copyrightSubmitted: false,
        });
    }
    handleCopyrightChange = async (e) => {
        this.setState({copyrightStatus: e.target.checked})
    }
    getCopyrightText = () => `আমি, ${this.state.AuthorNameInBengali}, ইমেইল ঠিকানা: ${this.state.AuthorEmail}, উদ্ভাসের সঙ্গে প্রতিজ্ঞাবদ্ধ হলাম যে '${categoryMap[this.state.Category||'']}' বিভাগে জমা করা আমার লেখা '${this.state.ArticleNameInBengali}', অপ্রকাশিত এবং মৌলিক রচনা। আমি উদ্ভাস পত্রিকাকে এই লেখাটির স্বত্ব প্রদান করলাম। উদ্ভাস পত্রিকার অনুমতি ব্যতিরেকে অদূর ভবিষ্যতে (ছয় মাসের মধ্যে) এই লেখাটি আমি অন্য কোথাও প্রকাশ করব না। এছাড়াও, এই লেখাটি উদ্ভাস পত্রিকায় প্রকাশিত হবার পর প্রকাশিত লেখার কোনও অংশ প্রকাশ তারিখের ছয় মাসের মধ্যে সামাজিক অন্তর্জালের মাধ্যমে পাঠকের জন্য উন্মুক্ত করব না। উন্মুক্ত অবস্থাতে উদ্ভাস পত্রিকার কথা উল্লিখিত থাকবে। উদ্ভাসের সম্মতি ব্যতিরেকে লেখাটি মুদ্রিত আকারে প্রকাশ করব না।`;

    async onFormSubmit(values) {
        const self = this;
        this.setState({processing: true});
        this.setState({status: null});
        this.setState({error: null});

        if (this.state.Category === null || this.state.Category === ''){
            this.setState({error: 'লেখার বিভাগ বেছে নিন'});
            this.setState({processing: false});
            return;
        }
        const config = {
            method: 'get',
            url: `${API_BASE}${GSHEET_API_PATH}?category=${this.state.Category}&submissionid=${this.state.SubmissionId}`,
            headers: {
                Authorization: 'Basic dWRiaGFzOkJlbmdhbGlMaXRAMjAyMA==',
                'Content-Type': "application/json",
            },
        };
        const updateResult = await axios(config)
            .catch(function (error) {
                //console.log(error);
                self.setState({error});
                self.setState({processing: null});
            });
        if (updateResult && updateResult.status === 200) {
            //console.log(updateResult)
            this.setState({status: updateResult?.data?.status});
            this.setState({ArticleNameInBengali: updateResult?.data?.name});
            this.setState({AuthorNameInBengali: updateResult?.data?.author});
            this.setState({AuthorEmail: updateResult?.data?.email});
            this.setState({Timestamp: updateResult?.data?.timestamp});
            this.setState({copyrightSubmitted: updateResult?.data.copyright !== undefined, copyrightStatus: updateResult?.data.copyright !== undefined})
            if (!this.state.copyrightStatus) {
                this.copyrightReset();
            }
            //console.log('copyrightStatus=', this.state.copyrightStatus)
        }
        if (updateResult && updateResult?.data?.error) {
            const category = this.state.Category && categoryMap[this.state.Category||''];
            this.setState({error: `আপনার লেখাটি (${this.state.SubmissionId}), (${category}) বিভাগে পাওয়া যাচ্ছে না`})
            this.setState({status: null});
            this.setState({ArticleNameInBengali: null});
            this.setState({Timestamp: null});
        }
        this.setState({processing: false});
    }

    async onCopyrightFormSubmit (values) {
        const self = this;
        this.setState({copyrightProcessing: true});
        this.setState({copyrightStatus: false});
        this.setState({copyrightError: null});

        const headers = {
            'Authorization': 'Basic dWRiaGFzOkJlbmdhbGlMaXRAMjAyMA==',
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        formData.append(
            "Category",
            this.state.Category ||'',
        );
        formData.append(
            "ArticleNameInBengali",
            this.state.ArticleNameInBengali ||'',
        );
        formData.append(
            "AuthorNameInBengali",
            this.state.AuthorNameInBengali ||'',
        );
        formData.append(
            "AuthorEmail",
            this.state.AuthorEmail ||'',
        );
        formData.append(
            "SubmissionId",
            this.state.SubmissionId || '',
        );
        formData.append(
            "CopyrightText",
            this.getCopyrightText(),
        );
        const uploadResult = await axios.post(`${API_BASE}${GDRIVE_API_PATH}`, formData, {headers} )
            .catch(function (error) {
                //console.log(error);
                toast.error(error.toString());
                self.setState({copyrightProcessing: false});
            });
        //console.log(uploadResult)
        if (uploadResult && uploadResult.data.success) {
            this.setState({copyrightSubmitted: true, copyrightStatus: true})
            const copyrightSuccess = `আপনার লেখাটির স্বত্ব জমা পড়েছে`
            this.setState({copyrightSuccess});
        }
        if (uploadResult && uploadResult?.data?.error) {
            this.setState({copyrightError: uploadResult?.data?.error});
        }
        this.setState({copyrightProcessing: false});

    }

    render() {
        return (
            <>
                <ValidatorForm  ref="form" onSubmit={this.onFormSubmit} color="primary.main">
                    <div>
                        <FormLabel><h1 style={{fontFamily: "Shonar Bangla"}}>আপনার লেখাটির বর্তমান পরিস্থিতি</h1></FormLabel>
                        <FormLabel><h3 style={{fontFamily: "Shonar Bangla"}}>আপনার লেখার বিভাগ বেছে নিয়ে সাবমিশন আইডি দিয়ে লেখাটির বর্তমান পরিস্থিতির অনুসন্ধান করুন</h3></FormLabel>
                        <hr style={{minWidth: 400}}/>
                    </div>
                    <div>
                        <FormLabel style={{minWidth: 400, textAlign: 'justify'}} htmlFor="Category"><h1 style={{fontFamily: "Shonar Bangla"}}>বিভাগ</h1></FormLabel>
                    </div>
                    <div>
                        <FormControl style={{minWidth: 400}}>
                            <NativeSelect
                                required
                                style={{minWidth: "400px"}}
                                name="Category" id="Category"
                                onChange={this.onCategoryChange}
                                value={this.state.Category}>
                                <option value=""> </option>
                                <option value="poem">কবিতা</option>
                                <option value="shonibarerkobita">শনিবারের কবিতা</option>
                                <option value="chhotoderchhora">ছোটোদের ছড়া</option>
                                <option value="shortstory">ছোটোগল্প</option>
                                <option value="microstory">অণুগল্প</option>
                                <option value="essay">প্রবন্ধ</option>
                                <option value="prose">মুক্ত গদ্য</option>
                                <option value="travelogue">ভ্রমণকাহিনি</option>
                                <option value="novel">উপন্যাস</option>
                                <option value="novella">উপন্যাসিকা</option>
                                <option value="robibarergolpo">রবিবারের গল্প</option>
                                <option value="chhotodergolpo">ছোটোদের গল্প</option>
                                <option value="romyorochona">রম্যরচনা</option>
                                <option value="other">চিঠিপত্র এবং অন্যান্য</option>
                            </NativeSelect>
                        </FormControl>
                    </div>
                    <div>
                        <FormLabel htmlFor="SubmissionId"><h1>Submission ID</h1></FormLabel>
                        <TextValidator
                            required
                            style={{minWidth: 400}}
                            label="Please enter your submission id"
                            name="SubmissionId"
                            value={this.state.SubmissionId||''}
                            validators={['required']}
                            onChange={this.handleSubmissionIdChange}
                            errorMessages={['এই তথ্যটি প্রয়োজন', 'সঠিক সাবমিশন আইডিটি লিখুন']}
                        />
                    </div>
                    <div><span>* প্রয়োজনীয় তথ্য</span></div>
                    <div style={{marginTop: 50, maxWidth: 400}}>
                        {(this.state.status || this.state.error) &&
                        <Card>
                            <CardPrimaryContent>
                                {this.state.status && <span>আপনার লেখাটির (সাবমিশন আইডি: {this.state.SubmissionId},
                                 নাম: {this.state.ArticleNameInBengali}, জমা করার সময়: {this.state.Timestamp}) বর্তমান পরিস্থিতি: {this.state.status}.</span>}
                                {this.state.error && <span>{this.state.error}</span>}
                            </CardPrimaryContent>
                        </Card>
                        }
                    </div>
                    <div style={{marginTop: 50}}>
                        {this.state.processing ?
                            <FormLabel><h1>Processing...<CircularProgress style={{verticalAlign: "middle"}}/></h1></FormLabel>
                            : <Button variant="contained" color="primary" style={{minWidth: 400, fontFamily: "Shonar Bangla"}}
                                      type='submit'>অনুসন্ধান করুন</Button>
                        }
                    </div>

                </ValidatorForm>
                {(this.state.status === 'Selected' || this.state.status === 'Submitted')&&
                    <ValidatorForm  ref="copyrightForm" onSubmit={this.onCopyrightFormSubmit} color="primary.main">
                        <div><FormLabel><h1 style={{fontFamily: "Shonar Bangla"}}>নিচের চেকবক্সে ক্লিক করে লেখাটির স্বত্ব প্রদান করুন </h1></FormLabel></div>
                        <div>
                        <input
                            type='checkbox'
                            name="copyrightStatus"
                            checked={this.state.copyrightStatus}
                            onChange={this.handleCopyrightChange}
                            disabled={this.state.copyrightSubmitted||false}
                        />
                        </div>
                        <div><span>{this.getCopyrightText()}</span></div>
                        <div>
                        {this.state.copyrightProcessing && <FormLabel><h1>Processing...<CircularProgress style={{verticalAlign: "middle"}}/></h1></FormLabel>}
                        {!this.state.copyrightProcessing && this.state.copyrightStatus && !this.state.copyrightSubmitted ?
                                    <Button variant="contained" color="primary"
                                            style={{minWidth: 400, fontFamily: "Shonar Bangla"}}
                                            type='submit'>লেখাটির স্বত্ব প্রদান করুন </Button>
                                    : null
                        }
                        {this.state.copyrightSubmitted &&
                            <div><span>লেখাটির স্বত্ব প্রদান করা হয়েছে</span></div>
                        }
                        </div>
                        <div style={{marginTop: 20, maxWidth: 400}}>
                            {(this.state.copyrightSuccess || this.state.copyrightError) &&
                            <Card>
                                <CardPrimaryContent>
                                    <span>{this.state. copyrightSuccess}</span>
                                    <span>{this.state.copyrightError}</span>
                                </CardPrimaryContent>
                            </Card>
                            }
                        </div>
                    </ValidatorForm>
                }
            </>

        );
    }
}

